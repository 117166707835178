.navbar-brand-nintendo {
    font-size: inherit;
}

a.nav-link.active {
    font-weight: 700;
}

@media print
{
    .navbar-brand-nintendo {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    /* Overrides browser option of not printing backgrounds to ensure nav-bar is displayed */
    .top-nav {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}