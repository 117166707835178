.access-code-container {
    padding: 15px 10px 15px 10px;
    height: 360px;
    background-color: #f1f1f1;
}

.access-code-input-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 37px;
    padding-left: 0;
    padding-right: 0;
}

.access-code-input {
    height: 56px;
    max-width: 834px;
    border-radius: 4px;
    border: 1px solid #CFCFCF;
    text-align: center;
    font-size: 21px;
}

.access-code-redeem {
    max-width: 581px;
    height: 64px;
    font-size: 16px;
    font-weight: 700;
}

.access-code-title {
    font-size: 21px;
    color: #484848;
    margin-left: auto;
    margin-right: auto;
    margin-top: 17px;
    margin-bottom: 27px;
}

.access-code-title-success {
    color: #E60012;
    text-align: center;
}

.access-code-exit-icon {
    color: #8991A6;
    font-size: 21px;
}

.access-code-link {
    color: #E60012;
}

.access-code-link:hover {
    color: #AC000D;
}

.access-code-link:focus {
    color: #AC000D;
    border-color: #E60012;
    box-shadow: rgb(230 0 18 / 30%) 0 0 0 4px;
}

.access-code-card {
    width: 310px;
}

.access-code-card-hide-ticket-text{
    width: 310px;
    color: transparent;
}

@media only screen and (min-width: 992px) {

    .access-code-redeem {
        font-size: 21px;
    }

    .access-code-title, .access-code-exit-icon, .access-code-input {
        font-size: 28px;
    }

    .access-code-container {
        background-color: #f1f1f1;
        padding: 15px 30px 15px 30px;
        height: 390px;
    }
}
