.alps-account-nav-default-style .alps-account-nav .alps-account-button .alps-account-name {
    color: #FFF !important;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

.alps-account-nav-default-style .alps-account-nav .alps-login-door .alps-login-door-button span {
    color: #FFF !important;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

.alps-login-door-button {
    padding-left: 0 !important;
}

.alps-login-door-icon {
    display: none !important;
}

.alps-account-nav {
    z-index: 1;
}

/* Printing styling */
@media print
{
    .hide-print, .hide-print *
    {
        display: none !important;
    }
}