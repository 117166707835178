.guest-modal-registration-info-tooltip > .tooltip-inner {
    min-width: 300px;
    max-width: 100%;
}

.text-align-left {
    text-align: left;
}

.remove-list-format {
    list-style-type: none;
}