.ribbon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 15px;
}

.frill-container {
    display: flex;
    flex-direction: row;
    z-index: 2;
    filter: drop-shadow(0px 1px 1px rgba(0,0,0,.25));
}

.frill-container-left {
    margin-right: -15px;
}

.frill-container-right {
    margin-left: -15px;
}

.point-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.frill-body {
    height: 40px;
    width: 20px;
    background-color: #ffdf02;
}

.frill-body-left {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.frill-body-right {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.ribbon-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    min-width: 350px;
    background-color: #ffdf02;
    height: 35px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    z-index: 3;
    font-weight: bold;
    flex-wrap: nowrap;
    padding-left: 0.2em;
    padding-right: 0.2em;
}

.frill-point {
    width: 0;
    height: 0;
}

.frill-point-left-top {
    border-bottom: 0;
    border-top: 20px solid #ffdf02;
    border-left: 20px solid transparent;
}

.frill-point-left-bottom {
    border-top: 0;
    border-bottom: 20px solid #ffdf02;
    border-left: 20px solid transparent;
}

.frill-point-right-top {
    border-bottom: 0;
    border-top: 20px solid #ffdf02;
    border-right: 20px solid transparent;
}

.frill-point-right-bottom {
    border-top: 0;
    border-bottom: 20px solid #ffdf02;
    border-right: 20px solid transparent;
}


/* Media query for xs / sm (phones) */
@media (max-width: 767px) {

    .ribbon-body {
        font-size: 1em;
    }
}
