.Toastify__toast--success {
    color: #447237;
    opacity: 0.98;
    padding-left: 12px;
    border-radius: 4px;
    background: #E0E5CF;
    border-color: #C3E6CB;
}

.Toastify__toast--error {
    color: #721C24;
    opacity: 0.98;
    padding-left: 12px;
    border-radius: 4px;
    border-color: #F5C6CB;
    background: #F8D7DA;
}

.Toastify__close-button--success {
    color: #447237;
}

.Toastify__close-button--error {
    color: #721c24;
}