
.block-header-success {
    color: #2D8513;
    margin-right: 5px;
}

.block-header-error {
    color: #E60012;
    margin-right: 5px;
}

.guest-block-guest-num {
    font-weight: 900;
}

.guest-block-remove-button-holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.guest-block-remove-guest-button,
.guest-block-remove-guest-button:active,
.guest-block-remove-guest-button:hover {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1.5rem;
}

.guest-block-remove-guest-button:focus {
    background-color: transparent;
    color: black;
    font-size: 1.5rem;
}

.guest-block-remove-guest-button.btn-primary:active {
    background-color: transparent;
    color: black;
}