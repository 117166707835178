.questionnaire-section-title {
    padding-left: 0.5em;
    border-left: 4px solid #3AA4FF;
    font-weight: 700;
}

.question-group {
    clear: both;
    margin: 0 1em 2em;
    background-color: #f1f1f1;
}

.level-1-question {
    margin-left: 0
}

.level-2-question {
    margin-left: 4em
}

.level-3-question {
    margin-left: 8em
}

.question-col {
    margin-top: 1em;
}