.footer {
    width: 100%;
    border-color: #dadada;
    padding: 1em 0;
    margin-top: 128px;
}
.footer p {
    margin-top: 1em;
}
.disclaimer-text {
    font-size: 12px;
}
.break {
    color: #CCC;
}