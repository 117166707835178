.ticket-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ticket-tooltip {
    z-index: 1;
}

.ml-2 svg {
    vertical-align: inherit;
}