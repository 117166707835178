/* Jim Lee's global site css styles */
body {
  font-family: museo-sans, Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: 700;
}
h4 {
  font-size: 18px;
  margin: 1em 0 1em 0;
}
h2 {
  font-size: 1.5em;
}
.my-ticket-day span {
  font-weight: normal;
}
dl {
  overflow: auto;
  margin-bottom: 2em;
}
dt, dd {
  float: left;
}
dt {
  clear: left;
  width: 100px;
  padding-right: 1em;
}
form label {
  font-weight: 700;
}
.form-check label {
  font-weight: 400;
}
.back-link {
  font-size: 12px;
  padding: 1.24em 0 0;
}
.toast {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 200;
  margin-left: 15px;
}
.toast .toast-body button {
  margin-top: 2px;
  margin-left: 10px;
  font-size: 18px;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.top-nav .nav-link-icon {
  margin-right: 10px;
}
.top-nav .nav-link-icon-rotated {
  transform: rotate(-20deg);
}

.nav-days a.nav-link,
.nav-tabs button.nav-link {
  background-color: #fff;
  color: #666;
}
.nav-days a.nav-link.active,
.nav-tabs button.nav-link.active {
  color: #fff;
  background-color: #666;
}
.nav-login {
  width: 40px;
  margin-left: 10px;
}
nav.navbar {
  background-color: #E8001E;
  color: #FFF;
  padding: .24rem .75rem;
}
nav.navbar a {
  color: #FFF;
}
.navbar-dark .navbar-toggler {
  color: #FFF;
  border-color: #E8001E;
}
.navbar-brand {
  margin: 0;
  padding: 0;
}
.navbar-brand img#img-logo {
  display: none;
  float: left;
  width: 100px;
  height: 100%;
}
.navbar-brand h1 {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.nav-days {
  margin: 0 -15px 0;
  padding: 1em 0 1em .5em;
  background: #e1e1e1;
}
.nav-days a {
  background-color: #999;
  color: #FFF;
  padding: .24em 1em;
  margin: 0 .24em;
}
.nav-days a.disabled {
  background-color: #d1d1d1;
  color: #AAA;
}
.nav-days a span {
  display: block;
  text-align: center;
}
.nav-days a span.date {
  font-weight: 900;
  font-size: 20px;
}
.view-options {
  list-style: none;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.view-options li {
  float: left;
  padding: .24em 1em;
}
.view-options li a {
  font-size: 40px;
  color: #E8001E;
}
.view-options li a.twitch {
  color: #4B367C;
}
.promo {
  margin: 0;
  padding: .24em 0;
  text-align: center;
  font-size: 12px;
}
.product-desc {
  font-weight:300;
  font-size:16px;
}
.product-desc p {
  margin-bottom: 0;
}
.product-desc strong {
  font-weight: bold!important;
}
.event-wrapper {
  padding: 1.5em 0 1em 0;
}
.event-wrapper .event-img img {
  border-radius: 4px;
  margin-bottom: .75em;
}
.event-date-location {
  font-size: 16px;
}
.event-dates {
  color: #484848;
  font-weight: 700;
}
.event-wrapper h3,
.event-detail h3
{
  font-weight: 600;
  color: #484848;
}
.event-wrapper h3 span,
.event-detail h3 span
{
  display:block;
  margin:0 0 .24em;
  font-size: .6em;
  color: #666;
  font-weight: 300;
}
.event-detail .event-img {
  margin: 0 -15px 15px;
}
.event-actions {
  display: none;
  float: right;
  font-size: 20px;
  color: #CCC;
}
.event-actions .fas.fa-heart {
  color: #E8001E;
}
.row-activities {
  border-top-width: 0;
  padding-top: 0;
  margin-top: 0;
}
.row-activities .col-sm-12 {
  padding-right: 0;
  padding-left: 0;
}
.activity-wrapper {
  padding: 1em;
  background: #f1f1f1;
  margin: 15px 0 15px;
  border-radius: 5px;
  flex-grow: 1;
}
.activity-wrapper .event-dates {
  color: #333;
  font-weight: 400;
  font-size: 16px;
}
.activity-wrapper .event-dates .time {
  display: block;
  font-size: 14px;
  color: #666;
}
.activity-wrapper .fa-plus-circle
{
  float: right;
  font-size: 26px;
}
.activity-wrapper .fa-minus-circle
{
  float: right;
  font-size: 26px;
  color: red;
}
.fa-chevron-right {
  font-size: 26px;
  color: #007bff;
}
.activity-wrapper .soldout {
  background-color: red;
  color: #FFF !important;
  display: inline-block;
  padding: 0 .5em;
  margin-bottom: .24em;
  font-size: 12px;
}
.activity-wrapper .event-type {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #6c757d;
}
p.soldout {
  text-align: center;
  color: #999;
  line-height: 22px;
  font-weight: 700;
}
.schedule-list h5 {
  display: none;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: .24em;
  /*	border-bottom: 1px solid #e1e1e1; */
}
.register-times {
  list-style: none;
  margin: 1em 0;
  padding: 0;
  overflow: auto;
}
.register-times li {
  float: left;
  display: inline-block;
  margin: 0 .5em .75em .5em;
}
.page-my-activities h6 {
  margin: 1.5em 0 .5em 0;
  color: #666;
}
.page-my-activities h6 strong {
  font-weight: 700;
  color: #333;
}
.actions-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 1em -15px 0;
  padding: 0 15px;
  overflow: auto;
  background-color: #E1E1E1;
  border-top: 1px solid #CCC;
}
.actions-bottom .btn {
  margin: .5em 0;
}
.tbl_event_details td {
  padding: 0 1em 1em 0;
  vertical-align: text-top;
}
.tbl_event_details td i {
  color: #666;
}

.page-my-activities .location {
  font-size: 14px;
}
.page-my-activities .btn-link,
.page-my-activities .btn-link:hover
{
  color: #666;
}
.dropleft .dropdown-toggle::before,
.dropdown-toggle::after
{
  display: none !important;
}

.section-header {
    background-color: #E1E1E1;
    padding: 0.5em;
    border-radius: 5px;
}

.form-label {
    line-height: 29px;
}

/* hide promo event code */
.promo-event,
#nav-promo-code
{
    display: none;
}

/* Media queries */

@media only screen and (min-width: 768px) {
  .event-detail p {
    font-size: 18px;
    font-weight: 300;
    margin: .75em 0 1em 0;
  }
  .event-wrapper {
    border-bottom-width: 0;
  }
  .actions-bottom {
    position: relative;
    overflow: auto;
    background-color: inherit;
    border-top-width: 0;
    margin: 2em 0;
    padding: 0;
  }
  .actions-bottom .btn {
    margin: 0;
  }
}
@media only screen and (min-width: 992px) {
  .navbar #mii-login {
    float: right;
  }
  .nav-item {
    padding: 0 1em;
  }
}
