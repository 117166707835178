.header {
    margin-top: 4em;
    margin-bottom: 8em;
}

.header b {
    margin-top: 1em;
}

.subsection {
    padding-bottom: 2em;
}

.subheader {
    font-weight: bold;
}