.divider {
    background-image: radial-gradient(rgb(200, 200, 200) 20%, transparent 20%);
    background-position: 0 0;
    background-size: 14px 14px;
    height: 14px;
    width: 40%;
}

.divider-full-width {
    width: 100%;
}

.divider-icon-rotated {
    color: #00000020;
    font-size: xx-large;
    padding-left: 5px;
    padding-right: 5px;
}