
.field-error-color {
    color: #E60012;
}

.generic-field-form-group {
    margin-bottom: 16px;
}

.generic-field-form-label {
    font-weight: 300;
}

.generic-field-form-control {
    border-width: 2px;
}

.generic-field-form-read-only {
    color: #212528;
    background-color: #E9ECEF;
}

.generic-field-form-error {
    text-align: right;
    color: #E60012;
    font-weight: 700;
}

.name-field-user-name {
    display: flex;
    align-items: center;
}

.name-field-spaced-text {
    margin-left: 1em;
}
